import { db } from '../plugins/firebase'

export interface State {
  documents: Array<Object>
  pdfjs: Object | null
}

export const state = (): State => ({
  documents: [],
  pdfjs: null,
})

export const getters = {
  activeDoc: (state, getters, rootState) => {
    if (!rootState.event.event) {
      return null
    }
    const doc = state.documents.find(
      (document) => document.id === rootState.event.event.activeDoc
    )
    if (!doc) {
      return null
    }
    return doc
  },
  activePage: (state, getters) => {
    if (!getters.activeDoc) {
      return 0
    }
    return getters.activeDoc.activePage
  },
}

export const mutations = {
  updatePdfJs(state: State, pdfjs: Object) {
    state.pdfjs = pdfjs
  },
}

export const actions = {
  async bindDocument(context) {
    const event = context.rootState.event.event
    if (event) {
      await context.dispatch(
        'bindQuery',
        {
          key: 'document.documents',
          query: db.collection('events/' + event.id + '/documents'),
        },
        { root: true }
      )
    }
  },
  async unbindDocument(context) {
    await context.dispatch('unbindFirestoreRef', 'document.documents', {
      root: true,
    })
  },
}
