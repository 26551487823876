<template>
  <PageLoader>
    <div class="hero is-fullheight">
      <div class="section">
        <span class="is-size-5 logo has-text-link">
          IR LIVE
        </span>
        <div class="columns is-mobile is-centered is-vcentered">
          <nuxt></nuxt>
        </div>
      </div>
    </div>
  </PageLoader>
</template>

<script>
import PageLoader from '~/components/PageLoader.vue'
export default {
  components: { PageLoader },
}
</script>

<style lang="scss" scoped>
main /deep/ .section {
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  .logo {
    align-self: center;
    margin-bottom: 10px;
  }
  .columns {
    flex-grow: 1;
    .column {
      max-width: 500px;
    }
  }
}
</style>
