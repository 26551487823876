import { db } from '@/plugins/firebase'

export interface State {
  formAnswer: Object
  surveys: Array<Object>
  questions: Array<Object>
  privateAnswer: Object | null
  publicAnswer: Object | null
  privateAnswers: Array<Object>
  publicAnswers: Array<Object>
}

export const state = (): State => ({
  formAnswer: {},
  surveys: [],
  questions: [],
  privateAnswer: null,
  publicAnswer: null,
  privateAnswers: [],
  publicAnswers: [],
})

export const getters = {
  activeSurvey: (state) => {
    return (
      state.surveys.find((survey) => survey.mode && survey.mode !== 'hide') ||
      null
    )
  },
  answer: (state) => {
    if (!state.privateAnswer || !state.publicAnswer) {
      return null
    }
    return { ...state.privateAnswer, ...state.publicAnswer }
  },
  answers: (state) => {
    const ids = [
      ...new Set([
        ...state.privateAnswers.map((q) => q.id),
        ...state.publicAnswers.map((q) => q.id),
      ]),
    ]
    return ids.map((id) => {
      return {
        id,
        ...(state.privateAnswers.find((q) => q.id === id) || {}),
        ...(state.publicAnswers.find((q) => q.id === id) || {}),
      }
    })
  },
}

export const mutations = {
  updateFormAnswer(state: State, formAnswer: Object) {
    state.formAnswer = formAnswer
  },
}

export const actions = {
  async bindSurvey(context) {
    const event = context.rootState.event.event
    if (event) {
      await context.dispatch(
        'bindQuery',
        {
          key: 'survey.surveys',
          query: db.collection('events/' + event.id + '/surveys'),
        },
        { root: true }
      )
    }
  },
  async bindAnswer(context) {
    const event = context.rootState.event.event
    const survey = context.getters.activeSurvey
    const participantId = context.rootState.event.participantId
    if (event && survey && participantId) {
      await context.dispatch(
        'bindDocRef',
        {
          key: 'survey.privateAnswer',
          collection:
            'events/' + event.id + '/surveys/' + survey.id + '/privateAnswers',
          doc: context.rootState.event.participantId,
        },
        { root: true }
      )
      await context.dispatch(
        'bindDocRef',
        {
          key: 'survey.publicAnswer',
          collection:
            'events/' + event.id + '/surveys/' + survey.id + '/publicAnswers',
          doc: context.rootState.event.participantId,
        },
        { root: true }
      )
    }
  },
  async bindAnswers(context, surveyId) {
    await context.dispatch('bindPublicAnswers', surveyId)
    await context.dispatch('bindPrivateAnswers', surveyId)
  },
  async bindPublicAnswers(context, surveyId) {
    const event = context.rootState.event.event
    if (event && surveyId) {
      await context.dispatch(
        'bindQuery',
        {
          key: 'survey.publicAnswers',
          query: db.collection(
            'events/' + event.id + '/surveys/' + surveyId + '/publicAnswers'
          ),
        },
        { root: true }
      )
    }
  },
  async bindPrivateAnswers(context, surveyId) {
    const event = context.rootState.event.event
    if (event && surveyId) {
      await context.dispatch(
        'bindQuery',
        {
          key: 'survey.privateAnswers',
          query: db.collection(
            'events/' + event.id + '/surveys/' + surveyId + '/privateAnswers'
          ),
        },
        { root: true }
      )
    }
  },
  async unbindAnswers(context) {
    await context.dispatch('unbindFirestoreRef', 'survey.privateAnswers', {
      root: true,
    })
    await context.dispatch('unbindFirestoreRef', 'survey.publicAnswers', {
      root: true,
    })
  },
  async unbindAnswer(context) {
    await context.dispatch('unbindFirestoreRef', 'survey.privateAnswer', {
      root: true,
    })
    await context.dispatch('unbindFirestoreRef', 'survey.publicAnswer', {
      root: true,
    })
  },
  async unbindSurvey(context) {
    await context.dispatch('unbindFirestoreRef', 'survey.surveys', {
      root: true,
    })
    await context.dispatch('unbindAnswerAndQuestions', null)
  },
  async unbindAnswerAndQuestions(context) {
    await context.dispatch('unbindAnswer', null)
    await context.dispatch('unbindAnswers', null)
    await context.dispatch('unbindQuestions', null)
  },
  async bindQuestions(context, surveyId) {
    const event = context.rootState.event.event
    if (event && surveyId) {
      await context.dispatch(
        'bindQuery',
        {
          key: 'survey.questions',
          query: db
            .collection('events/' + event.id + '/surveys')
            .doc(surveyId)
            .collection('questions'),
        },
        { root: true }
      )
    }
  },
  async unbindQuestions(context) {
    await context.dispatch('unbindFirestoreRef', 'survey.questions', {
      root: true,
    })
  },
}
