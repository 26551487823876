import { db } from '@/plugins/firebase'

export type User = {
  email: string
  displayName: string
  emailVerified: boolean
  uid: string
} | null

export interface State {
  user: User
  userData: Object | null
  authenticated: boolean
  continueUrl: String | null
  company: String
  firstName: String
  lastName: String
  displayName: String
}

export const state = (): State => ({
  user: null,
  userData: null,
  authenticated: false,
  continueUrl: null,
  company: '',
  firstName: '',
  lastName: '',
  displayName: '',
})

export const getters = {
  isAdmin: (state) => {
    return state.userData && state.userData.admin
  },
}

export const mutations = {
  updateUser(state: State, user: User) {
    const userObj = user
      ? {
          email: user.email,
          displayName: user.displayName,
          emailVerified: user.emailVerified,
          uid: user.uid,
        }
      : user
    state.user = userObj
  },
  updateAuthStatus(state: State, authenticated: boolean) {
    state.authenticated = authenticated
  },
  updateContinueUrl(state: State, continueUrl: String) {
    state.continueUrl = continueUrl
  },
  updateValueByKey(state: State, { key, value }) {
    state[key] = value
  },
}

export const actions = {
  async authenticate(context, user: firebase.default.User) {
    const checkUserData = async () => {
      if (user) {
        const docRef = db.collection('users').doc(user.uid)
        const doc = await docRef.get()
        if (!doc.exists) {
          if (
            !context.state.company ||
            !context.state.displayName ||
            !context.state.lastName ||
            !context.state.firstName
          ) {
            await context.dispatch('unbindFirestoreRef', 'auth.userData', {
              root: true,
            })
            return false
          }
          await docRef.set({
            email: user.email || '',
            displayName: context.state.displayName,
            company: context.state.company,
            firstName: context.state.firstName,
            lastName: context.state.lastName,
            admin: false,
          })
        }
        await context.dispatch(
          'bindDocRef',
          {
            key: 'auth.userData',
            collection: 'users',
            doc: user.uid,
          },
          { root: true }
        )
        return true
      } else {
        await context.dispatch('unbindFirestoreRef', 'auth.userData', {
          root: true,
        })
        return null
      }
    }
    const result = await checkUserData()
    context.commit('updateAuthStatus', true)
    context.commit('updateUser', result ? user : null)
    return result
  },
}
