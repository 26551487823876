import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyBKSXNYpVrh44aK3V6FDzvJRIPcbQqf9Ss',
  authDomain: 'press-co.firebaseapp.com',
  databaseURL: 'https://press-co.firebaseio.com',
  projectId: 'press-co',
  storageBucket: 'press-co.appspot.com',
  messagingSenderId: '416698918377',
  appId: '1:416698918377:web:cdabfeae1403b528829778',
  measurementId: 'G-RK3JG8CBJ6',
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

const auth = firebase.auth()
firebase.auth().useDeviceLanguage()
const firestore = firebase.firestore
const db = firebase.firestore()
const functions = firebase.functions()
const storage = firebase.storage()

if (process.browser) {
  require('firebase/analytics')
  firebase.analytics()
  require('firebase/performance')
  firebase.performance()
}

export { firebase, firestore, auth, db, functions, storage }
