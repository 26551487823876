import { vuexfireMutations } from 'vuexfire'
import { firestoreAction } from 'vuexfire'
import { db } from '@/plugins/firebase'

export const mutations = {
  ...vuexfireMutations,
}

export const actions = {
  bindDocRef: firestoreAction(
    async (context, { key: key, collection: collection, doc: doc }) => {
      if (doc) {
        await context.bindFirestoreRef(
          key,
          db.collection(collection).doc(doc),
          {
            wait: true,
          }
        )
      }
    }
  ),
  bindQuery: firestoreAction(async (context, { key: key, query: query }) => {
    await context.bindFirestoreRef(key, query, { wait: true })
  }),
  unbindFirestoreRef: firestoreAction(async (context, key) => {
    await context.unbindFirestoreRef(key)
  }),
}
