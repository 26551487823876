<template>
  <TransitionGroup tag="main">
    <div
      v-if="!authenticated || !loaded"
      id="PageLoader"
      key="loader"
      class="hero is-fullheight"
    >
      <div class="hero-body">
        <div class="container has-text-centered">
          <button class="button is-white is-loading is-large"></button>
        </div>
      </div>
    </div>
    <div
      v-show="authenticated && loaded"
      id="loadedPage"
      key="page"
      class="has-background-white-bis"
    >
      <slot></slot>
    </div>
  </TransitionGroup>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import smoothscroll from 'smoothscroll-polyfill'
import { auth } from '~/plugins/firebase'
export default {
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapState('auth', {
      user: 'user',
      authenticated: 'authenticated',
      continueUrl: 'continueUrl',
    }),
  },
  mounted() {
    smoothscroll.polyfill()
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.$sentry.configureScope((scope) => {
          scope.setUser({
            email: user.email,
            username: user.displayName,
            id: user.uid,
          })
        })
      }
      const result = await this.authenticate(user)
      if (result === false) {
        try {
          await user.delete()
        } catch (error) {
          await auth.signOut()
        }
        window.alert(
          'アカウントデータが存在しません。アカウント作成ページに移動します。'
        )
        this.$router.push('/signup')
        return
      }
      const layout = this.$nuxt.$data.layoutName
      const isMypage = layout === 'mypage'
      const isSignedIn = !!result
      const routeChange = isMypage !== isSignedIn
      if (routeChange) {
        if (!isSignedIn) {
          this.updateContinueUrl(this.$route.path)
        }
        this.$router.push(isSignedIn ? '/' : '/signin')
      }
      const timeout = routeChange ? 250 : 0
      setTimeout(() => {
        this.loaded = true
      }, timeout)
    })
  },
  methods: {
    ...mapMutations({
      updateContinueUrl: 'auth/updateContinueUrl',
    }),
    ...mapActions({
      authenticate: 'auth/authenticate',
    }),
  },
}
</script>

<style lang="scss" scoped>
#PageLoader {
  pointer-events: none;
  width: 100vw;
  position: absolute;
}
main /deep/ #loadedPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
</style>
