import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2cc13bb4 = () => interopDefault(import('../src/pages/events.vue' /* webpackChunkName: "pages/events" */))
const _5cc00953 = () => interopDefault(import('../src/pages/managements.vue' /* webpackChunkName: "pages/managements" */))
const _4c9fdad2 = () => interopDefault(import('../src/pages/reset.vue' /* webpackChunkName: "pages/reset" */))
const _7ae0842f = () => interopDefault(import('../src/pages/signin.vue' /* webpackChunkName: "pages/signin" */))
const _e1124ab6 = () => interopDefault(import('../src/pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _2d7171ea = () => interopDefault(import('../src/pages/users.vue' /* webpackChunkName: "pages/users" */))
const _5baa49c8 = () => interopDefault(import('../src/pages/event/_id.vue' /* webpackChunkName: "pages/event/_id" */))
const _f6bffe5e = () => interopDefault(import('../src/pages/invitation/_id.vue' /* webpackChunkName: "pages/invitation/_id" */))
const _41eb7b60 = () => interopDefault(import('../src/pages/invitation/_id/signin.vue' /* webpackChunkName: "pages/invitation/_id/signin" */))
const _18bece74 = () => interopDefault(import('../src/pages/invitation/_id/signup.vue' /* webpackChunkName: "pages/invitation/_id/signup" */))
const _a76445c6 = () => interopDefault(import('../src/pages/manage/_id.vue' /* webpackChunkName: "pages/manage/_id" */))
const _84a4a3be = () => interopDefault(import('../src/pages/public/_id.vue' /* webpackChunkName: "pages/public/_id" */))
const _b8452656 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/events",
    component: _2cc13bb4,
    name: "events"
  }, {
    path: "/managements",
    component: _5cc00953,
    name: "managements"
  }, {
    path: "/reset",
    component: _4c9fdad2,
    name: "reset"
  }, {
    path: "/signin",
    component: _7ae0842f,
    name: "signin"
  }, {
    path: "/signup",
    component: _e1124ab6,
    name: "signup"
  }, {
    path: "/users",
    component: _2d7171ea,
    name: "users"
  }, {
    path: "/event/:id?",
    component: _5baa49c8,
    name: "event-id"
  }, {
    path: "/invitation/:id?",
    component: _f6bffe5e,
    name: "invitation-id",
    children: [{
      path: "signin",
      component: _41eb7b60,
      name: "invitation-id-signin"
    }, {
      path: "signup",
      component: _18bece74,
      name: "invitation-id-signup"
    }]
  }, {
    path: "/manage/:id?",
    component: _a76445c6,
    name: "manage-id"
  }, {
    path: "/public/:id?",
    component: _84a4a3be,
    name: "public-id"
  }, {
    path: "/",
    component: _b8452656,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
