<template>
  <span class="icon" :class="{ 'is-small': isSmall }">
    <i
      class="mdi"
      :class="{ ['mdi-' + icon]: true, ['mdi-' + size + 'px']: !!size }"
    ></i>
  </span>
</template>

<script>
export default {
  props: {
    icon: { type: String, required: true },
    size: { type: String, default: null },
    isSmall: { type: Boolean, default: false },
  },
}
</script>
