<template>
  <TransitionGroup tag="div" class="invitation-layout">
    <section v-if="event" key="top-bg" class="top-bg has-background-link" />
    <div v-if="!event" key="loading" class="loading has-background-link">
      <button class="button is-loading is-large is-link" />
    </div>
    <section v-else key="section" class="section">
      <div class="columns is-centered">
        <div class="column is-narrow">
          <StorageImage
            v-if="event.banner"
            :event="event"
            path="banner"
            class="has-text-centered"
          />
          <h1
            v-if="event.lpHeader"
            class="title is-4 has-text-weight-medium has-text-centered"
          >
            {{ event.lpHeader }}
          </h1>
          <div class="event-top">
            <div class="event-info is-size-7">
              <div class="info-row">
                <div class="info-header">
                  開催日時
                </div>
                <div v-if="eventDate" class="info-value">
                  {{ eventDate }}
                </div>
              </div>
            </div>
            <div class="lp-logo">
              <StorageImage :event="event" />
            </div>
          </div>
          <p class="details is-size-7" v-text="event.eventDetails || ''"></p>
        </div>
      </div>
      <nuxt />
    </section>
    <footer
      v-if="event"
      key="footer"
      class="footer has-text-white has-text-centered"
    >
      <div>
        {{
          'Copyright ©' +
          (year || '2020') +
          ' Kartz Media Works, Inc. All rights reserved.'
        }}
      </div>
      <div class="footer-links">
        <a
          href="https://www.kartz.co.jp/privacypolicy/"
          target="_blank"
          class="has-text-white"
        >
          プライバシーポリシー
        </a>
        <span>|</span>
        <a
          href="https://www.kartz.co.jp/page/$/page_id/7638/"
          target="_blank"
          class="has-text-white"
        >
          個人情報の取扱いについて
        </a>
      </div>
    </footer>
  </TransitionGroup>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { auth } from '~/plugins/firebase'
import StorageImage from '~/components/StorageImage.vue'
export default {
  components: { StorageImage },
  data() {
    return {
      year: null,
    }
  },
  computed: {
    ...mapState('event', {
      event: 'event',
    }),
    eventDate() {
      if (!this.event || !this.event.start) {
        return ''
      }
      const start = new Date(this.event.start.seconds * 1000)
      const end = new Date(this.event.end.seconds * 1000)
      return (
        start.toLocaleDateString('ja-JP', {
          weekday: 'short',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }) +
        ' ' +
        start.getHours() +
        '時' +
        ('00' + start.getMinutes()).slice(-2) +
        '分' +
        '〜' +
        end.getHours() +
        '時' +
        ('00' + end.getMinutes()).slice(-2) +
        '分'
      )
    },
  },
  mounted() {
    this.bindEvent(this.$route.params.id)
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.$sentry.configureScope((scope) => {
          scope.setUser({
            email: user.email,
            username: user.displayName,
            id: user.uid,
          })
        })
      }
      this.authenticate(user)
    })
  },
  beforeMount() {
    const today = new Date()
    this.year = String(today.getFullYear())
  },
  methods: {
    ...mapActions({
      bindEvent: 'event/bindEvent',
      authenticate: 'auth/authenticate',
    }),
  },
}
</script>

<style lang="scss" scoped>
.invitation-layout {
  width: 100%;
  height: 100%;
  overflow: auto;
  .top-bg {
    height: 1em;
    width: 100%;
  }
  &/deep/ .banner {
    margin: auto;
    margin-bottom: 3em;
  }
  .loading {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section {
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
  .event-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 2em 0;
    .event-info {
      display: flex;
      flex-direction: column;
      .info-row {
        display: flex;
        flex-direction: row;
        margin: 0.5em 0;
        .info-header {
          font-weight: bold;
          width: 7em;
        }
      }
    }
    .lp-logo {
      margin-left: 3em;
    }
  }
  .details {
    white-space: pre-wrap;
    max-width: 800px;
    padding: 1em;
  }
  .footer {
    .footer-links {
      display: flex;
      justify-content: center;
      a {
        margin: 0 1em;
      }
    }
  }
}
@media (max-width: $tablet) {
  .invitation-layout {
    .event-top {
      flex-direction: column-reverse;
      .lp-logo {
        margin-left: 0em;
        margin-bottom: 2em;
        margin-top: 2em;
      }
    }
  }
}
</style>
