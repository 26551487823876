<template>
  <div>
    <object
      v-if="src && event[path].endsWith('.svg')"
      class="StorageImage"
      type="image/svg+xml"
      :class="{ [path]: true }"
      :data="src"
    />
    <img
      v-if="src && !event[path].endsWith('.svg')"
      class="StorageImage"
      :class="{ [path]: true }"
      :src="src"
      :alt="alt"
    />
  </div>
</template>

<script>
import { storage } from '@/plugins/firebase'
export default {
  props: {
    event: { type: Object, required: true },
    path: { type: String, default: 'logo' },
  },
  data() {
    return {
      src: null,
    }
  },
  computed: {
    fileName() {
      return this.event[this.path] || null
    },
    imageRef() {
      return this.fileName
        ? storage.ref(this.event.id + '/' + this.path + '/' + this.fileName)
        : null
    },
    alt() {
      return this.path === 'logo' ? this.event.title : this.event.lpHeader
    },
  },
  watch: {
    imageRef() {
      this.getSrc()
    },
  },
  mounted() {
    this.getSrc()
  },
  methods: {
    async getSrc() {
      if (this.imageRef) {
        try {
          const url = await this.imageRef.getDownloadURL()
          this.src = url
        } catch (error) {
          this.$sentry.captureException(error.message)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
img,
object {
  &.StorageImage {
    height: auto;
    width: auto;
    &.logo {
      max-width: 150px;
      max-height: 50px;
    }
    &.banner {
      max-width: 600px;
      width: 100%;
    }
  }
}
</style>
