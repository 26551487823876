import { db } from '@/plugins/firebase'

export interface State {
  binded: Boolean
  users: Array<Object>
}

export const state = (): State => ({
  binded: false,
  users: [],
})

export const mutations = {
  updateBindStatus(state: State, binded: boolean) {
    state.binded = binded
  },
}

export const actions = {
  async bindUsers(context) {
    const userData = context.rootState.auth.userData
    const isAdmin = userData && userData.admin
    if (isAdmin) {
      await context.dispatch(
        'bindQuery',
        {
          key: 'user.users',
          query: db.collection('users'),
        },
        { root: true }
      )
      context.commit('updateBindStatus', true)
    } else {
      context.dispatch('unbindUsers', true)
    }
  },
  async unbindUsers(context) {
    await context.dispatch('unbindFirestoreRef', 'user.users', {
      root: true,
    })
    context.commit('updateBindStatus', false)
  },
}
