import { db } from '@/plugins/firebase'

export interface State {
  binded: Boolean
  messages: Array<Object>
}

export const state = (): State => ({
  binded: false,
  messages: [],
})

export const getters = {
  lastMessage: (state) => {
    if (state.messages.length === 0) {
      return null
    }
    return state.messages[0]
  },
}

export const mutations = {
  updateBindStatus(state: State, binded: boolean) {
    state.binded = binded
  },
}

export const actions = {
  async bindLastMessage(context, eventId) {
    context.commit('updateBindStatus', false)
    const collection = db
      .collection('events')
      .doc(eventId)
      .collection('messages')
    await context.dispatch(
      'bindQuery',
      {
        key: 'message.messages',
        query: collection.orderBy('time', 'desc').limit(20),
      },
      { root: true }
    )
    context.commit('updateBindStatus', true)
  },
  async unbindLastMessage(context) {
    await context.dispatch('unbindFirestoreRef', 'message.messages', {
      root: true,
    })
  },
}
