import { firebase, db } from '@/plugins/firebase'

export type Event = {
  id: String
  public: String
  password: String
  managers: Array<String>
  questionViewing: String
} | null

export type Participant = {
  uid?: String
  id?: String
  peerId: String | null
  lastView: Date
}

export interface State {
  binded: Boolean
  events: Array<Event>
  managingEvents: Array<Event>
  event: Event
  participants: Array<Participant>
  participant: Participant | null
  participantId: String | null
  callingId: String | null
  callingParticipant: String | null
  lastViewInterval: String | null
  onlyUser: Boolean | null
  displayName: String
  password: String
}

export const state = (): State => ({
  binded: false,
  event: null,
  events: [],
  managingEvents: [],
  participants: [],
  participant: null,
  participantId: null,
  callingId: null,
  callingParticipant: null,
  lastViewInterval: null,
  onlyUser: null,
  displayName: '',
  password: '',
})

export const getters = {
  accountRequired(state: State) {
    return state.event && state.event.public !== 'password'
  },
  readyForPasswordEvent(state: State) {
    return (
      state.event &&
      state.event.public === 'password' &&
      state.event.password === state.password &&
      !!state.displayName &&
      !!state.participantId
    )
  },
  allEvents(state: State) {
    const ids = state.events.map((event) => (event ? event.id : ''))
    return [
      ...state.events,
      ...state.managingEvents.filter(
        (event) => event && !ids.includes(event.id)
      ),
    ]
  },
  isManager(state: State, getters, rootState, rootGetters) {
    const event = state.event
    if (!event || !event.managers) {
      return false
    }
    const userData = rootState.auth.userData
    if (!userData) {
      return false
    }
    const email = userData.email
    return email && event.managers.includes(email)
  },
  questionsArePublic(state: State) {
    return state.event && state.event.questionViewing === 'public'
  },
}

export const mutations = {
  updateBindStatus(state: State, binded: boolean) {
    state.binded = binded
  },
  updateParticipantsSetting(state: State, onlyUser: boolean) {
    state.onlyUser = onlyUser
  },
  updateDisplayName(state: State, displayName: String) {
    state.displayName = displayName
  },
  updatePassword(state: State, password: String) {
    state.password = password
  },
  updateParticipantId(state: State, participantId: String) {
    state.participantId = participantId
  },
  updateLastViewInterval(state: State, intervalId: String) {
    state.lastViewInterval = intervalId
  },
  updateCallingIds(
    state: State,
    payload: { peerId: String; participantId: String }
  ) {
    state.callingId = payload.peerId
    state.callingParticipant = payload.participantId
  },
}

export const actions = {
  async getEvents(context) {
    context.commit('updateBindStatus', false)
    let query: firebase.firestore.Query = db.collection('events')
    const userData = context.rootState.auth.userData
    if (!userData || !userData.admin) {
      query = query.where('public', '==', 'public')
    }
    await context.dispatch(
      'bindQuery',
      {
        key: 'event.events',
        query: query.orderBy('start', 'desc'),
      },
      { root: true }
    )
    await context.dispatch('getManagingEvents', true)
    context.commit('updateBindStatus', true)
  },
  async getManagingEvents(context) {
    let query: firebase.firestore.Query = db.collection('events')
    const userData = context.rootState.auth.userData
    if (!userData || !userData.admin) {
      query = query.where(
        'managers',
        'array-contains',
        userData ? userData.email : ''
      )
    }
    await context.dispatch(
      'bindQuery',
      {
        key: 'event.managingEvents',
        query: query.orderBy('start', 'desc'),
      },
      { root: true }
    )
  },
  async bindEvent(context, eventId) {
    await context.dispatch(
      'bindDocRef',
      {
        key: 'event.event',
        collection: 'events',
        doc: eventId,
      },
      { root: true }
    )
    await context.dispatch('message/bindLastMessage', eventId, { root: true })
    await context.dispatch('document/bindDocument', eventId, { root: true })
    await context.dispatch('survey/bindSurvey', eventId, { root: true })
  },
  async unbindEvent(context) {
    await context.dispatch('unbindFirestoreRef', 'event.event', {
      root: true,
    })
    await context.dispatch('unbindFirestoreRef', 'event.participant', {
      root: true,
    })
    await context.dispatch('unbindFirestoreRef', 'event.participants', {
      root: true,
    })
    await context.dispatch('message/unbindLastMessage', true, { root: true })
    await context.dispatch('document/unbindDocument', true, { root: true })
    await context.dispatch('survey/unbindSurvey', true, { root: true })
  },
  async bindParticipants(context, { onlyUser, participantId }) {
    const event = context.state.event
    if (event) {
      const doc = db.collection('events').doc(event.id)
      const collection = doc.collection('participants')
      if (!onlyUser) {
        await context.dispatch('unbindFirestoreRef', 'event.participant', {
          root: true,
        })
        await context.dispatch(
          'bindQuery',
          {
            key: 'event.participants',
            query: collection,
          },
          { root: true }
        )
      } else {
        await context.dispatch('unbindFirestoreRef', 'event.participants', {
          root: true,
        })
        context.commit('updateParticipantId', participantId || null)
        if (context.rootState.auth.user) {
          const querySnap = await collection
            .where('uid', '==', context.rootState.auth.user.uid)
            .limit(1)
            .get()
          if (!querySnap.empty) {
            const docSnap = querySnap.docs[0]
            const userParticipantId = docSnap.id
            context.commit('updateParticipantId', userParticipantId)
          }
        }
        const now = new Date()
        const basePayload: Participant = {
          lastView: now,
          peerId: null,
          uid: context.rootState.auth.user
            ? context.rootState.auth.user.uid
            : null,
        }
        const payload = context.rootState.auth.userData
          ? {
              ...basePayload,
              ...context.rootState.auth.userData,
              displayName:
                context.state.displayName ||
                context.rootState.auth.userData.displayName,
            }
          : { ...basePayload, displayName: context.state.displayName }

        const isNew = !context.state.participantId
        if (isNew) {
          const newParticipantId = collection.doc().id
          context.commit('updateParticipantId', newParticipantId)
        }

        const docRef = collection.doc(context.state.participantId)
        if (isNew) {
          await docRef.set({ ...payload, firstView: now })
        } else {
          await docRef.update(payload)
        }

        await context.dispatch(
          'bindDocRef',
          {
            key: 'event.participant',
            collection: 'events/' + event.id + '/participants',
            doc: context.state.participantId,
          },
          { root: true }
        )
      }
    }
  },
}
