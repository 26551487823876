<template>
  <PageLoader>
    <div v-if="user" class="page-wrapper has-background-white-bis">
      <nav class="navbar is-transparent">
        <div class="navbar-brand">
          <div v-if="$route.path.includes('/event/')" class="navbar-item">
            <NuxtLink to="/events">
              <MaterialIcon icon="arrow-left" />
              <span>一覧に戻る</span>
            </NuxtLink>
          </div>
          <NuxtLink v-else class="navbar-item" to="/">
            <span class="logo is-size-5 has-text-link">
              IR LIVE
            </span>
          </NuxtLink>
          <a
            role="button"
            class="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            :class="{ 'is-active': active }"
            @click="active = !active"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div class="navbar-menu" :class="{ 'is-active': active }">
          <div class="navbar-start"></div>
          <div class="navbar-end">
            <NuxtLink
              class="navbar-item is-tab"
              :class="{ 'is-active': isActive('/events') }"
              to="/events"
            >
              <MaterialIcon icon="calendar-search" />
              <span>イベントに参加</span>
            </NuxtLink>
            <NuxtLink
              class="navbar-item is-tab"
              :class="{ 'is-active': isActive('/managements') }"
              to="/managements"
            >
              <MaterialIcon icon="calendar-edit" />
              <span>イベントを主催</span>
            </NuxtLink>
            <NuxtLink
              v-if="isAdmin"
              class="navbar-item is-tab"
              :class="{ 'is-active': isActive('/users') }"
              to="/users"
            >
              <MaterialIcon icon="account-group" />
              <span>ユーザー管理</span>
            </NuxtLink>
            <div class="navbar-item">
              <div class="navbar-item has-dropdown is-right is-hoverable">
                <div v-if="userData || user" class="navbar-link">
                  <span class="icon">
                    <i class="mdi mdi-account-circle"></i>
                  </span>
                  <span class="is-size-7">
                    {{ userData ? userData.displayName : user.email }}
                  </span>
                </div>
                <div class="navbar-dropdown is-right">
                  <a class="navbar-item" @click="signOut">
                    <span class="icon">
                      <i class="mdi mdi-logout"></i>
                    </span>
                    ログアウト
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <section class="section mypage-body">
        <nuxt />
      </section>
    </div>
  </PageLoader>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { auth } from '~/plugins/firebase'
import PageLoader from '~/components/PageLoader.vue'
import MaterialIcon from '~/components/MaterialIcon.vue'
export default {
  components: { PageLoader, MaterialIcon },
  data() {
    return {
      active: false,
    }
  },
  computed: {
    ...mapState('auth', {
      user: 'user',
      userData: 'userData',
    }),
    ...mapGetters('auth', {
      isAdmin: 'isAdmin',
    }),
  },
  methods: {
    async signOut() {
      await auth.signOut()
    },
    isActive(path) {
      const current = this.$route.path
      if (path === '/events' && current.includes('/event/')) {
        return true
      }
      if (path === '/managements' && current.includes('/manage/')) {
        return true
      }
      return current.includes(path)
    },
  },
}
</script>

<style lang="scss" scoped>
main /deep/ .mypage-body {
  height: 0%;
  flex-grow: 1;
  position: relative;
  overflow: auto;
  padding-bottom: 80px;
  .columns {
    align-self: stretch;
  }
}
main /deep/ .page-wrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-width: 340px;
  @include mq-tablet {
    min-height: auto;
  }
}
</style>
